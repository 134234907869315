import {Typography} from '@mui/material';

export function LaunchPadEntityName(props: {entityKindName: string; entityName: string; 'data-testid': string}) {
  return (
    <div style={{lineHeight: '20px'}}>
      <Typography sx={{display: 'inline', fontSize: 13, mr: '10px', lineHeight: '20px'}}>
        {props.entityKindName}
      </Typography>
      <Typography
        sx={{display: 'inline', fontSize: 13, color: '#8dabc4', lineHeight: '20px'}}
        data-testid={props['data-testid']}
      >
        {props.entityName}
      </Typography>
    </div>
  );
}
