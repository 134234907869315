import {KTooltipIcon, KTooltipIconProps} from '@/components/alerts/KTooltipIcon';
import {FC, ReactNode} from 'react';
import {KCard, KCardProps} from './KCard';
import {KRow} from './KRow';
import {KStackProps} from './KStack';

export type KWidgetProps = Partial<KCardProps> & {
  'data-testid': string;
  tooltipProps?: KTooltipIconProps;
  actions?: ReactNode[];
  actionRowProps?: KStackProps;
  name?: ReactNode;
  value?: ReactNode;
};

/**
 * Wrap KCard with default settings with tooltip and action options.
 *
 * Children are children of wrapped KCard.
 *
 * Optional tooltip with icon is positioned top-right by default.
 *
 * Optional actions are wrapped by KRow that is positioned over the
 * card by default.
 *
 */
export const KWidget: FC<KWidgetProps> = ({
  tooltipProps,
  actions,
  children,
  sx,
  actionRowProps,
  name,
  value,
  ...cardProps
}) => (
  <KCard variant={'outlined'} {...cardProps} sx={{position: 'relative', p: 2, pt: 1, ...sx}}>
    {name}

    {value}

    {children}

    {tooltipProps && <KTooltipIcon {...tooltipProps} />}

    {!!actions?.length && (
      <KRow
        {...actionRowProps}
        sx={{
          position: 'absolute',
          left: 10,
          top: 10,
          right: tooltipProps?.text ? 35 : 10,
          bottom: 10,
          ...actionRowProps?.sx,
        }}
      >
        {actions}
      </KRow>
    )}
  </KCard>
);
