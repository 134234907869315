import {KIconOrganization, KIconSite, KIconSponsorColumns, KIconStudy, type IconComponent} from '@/components/icons/fa';
import {KRow} from '@/components/layout/KRow';
import {KStack} from '@/components/layout/KStack';
import {KWidget} from '@/components/layout/KWidget';
import colors from '@/styles/colors';
import {
  RoleScope,
  passportRoleScopeOrganizationIDKey,
  passportRoleScopeSiteIDKey,
  passportRoleScopeSponsorIDKey,
  passportRoleScopeStudyIDKey,
} from '@koneksahealth/ts-authorizer';
import {Button, Typography} from '@mui/material';
import Link from 'next/link';
import {LaunchPadEntityName} from './LaunchPadEntityName';

export type LaunchPadScope = RoleScope & {
  path: string;
  kind: 'organization' | 'sponsor' | 'study' | 'site';
};

const iconByKind: Record<LaunchPadScope['kind'], IconComponent> = {
  organization: KIconOrganization,
  sponsor: KIconSponsorColumns,
  study: KIconStudy,
  site: KIconSite,
};

/** A type that states that if studyName is defined, for example, that sponsor and org must also be defined */
type Names =
  | {
      orgName: string;
      sponsorName?: undefined;
      studyName?: undefined;
      siteName?: undefined;
    }
  | {
      orgName: string;
      sponsorName: string;
      studyName?: undefined;
      siteName?: undefined;
    }
  | {
      orgName: string;
      sponsorName: string;
      studyName: string;
      siteName?: undefined;
    }
  | {
      orgName: string;
      sponsorName: string;
      studyName: string;
      siteName: string;
    };

type LaunchPadWidgetProps = {
  scope: LaunchPadScope;
  'data-testid': string;
} & Names;

export function LaunchPadWidget({scope, 'data-testid': tid, ...names}: LaunchPadWidgetProps) {
  const entityName = names.siteName || names.studyName || names.sponsorName || names.orgName;
  const Icon = iconByKind[scope.kind];
  return (
    <KWidget
      sx={{fontSize: '16px', width: 295, p: '50px 20px 20px', mt: '30px', overflow: 'visible'}}
      actionRowProps={{sx: {alignItems: 'flex-start', justifyContent: 'center', mt: '-40px', height: 60}}}
      actions={[
        <KRow
          justifyContent={'center'}
          key={`${tid}-icon`}
          sx={{width: 60, height: 60, borderRadius: '50%', backgroundColor: colors.cobalt, color: colors.calcite}}
        >
          <Icon fontSize={30} />
        </KRow>,
      ]}
      key={`${scope[passportRoleScopeOrganizationIDKey]}::${scope[passportRoleScopeSponsorIDKey]}::${scope[passportRoleScopeStudyIDKey]}::${scope[passportRoleScopeSiteIDKey]}`}
      name={
        <Typography
          sx={{fontSize: 16, fontWeight: 600, marginTop: '45px'}}
          noWrap
          title={entityName}
          data-testid={`${tid}-title`}
        >
          {entityName}
        </Typography>
      }
      data-testid={`${tid}-entity`}
    >
      <KStack gap={1} sx={{minHeight: '125px', mt: '10px'}}>
        {names.sponsorName && (
          <LaunchPadEntityName
            entityKindName="Organization"
            entityName={names.orgName}
            data-testid={`${tid}-org-name`}
          />
        )}

        {names.studyName && (
          <LaunchPadEntityName
            entityKindName="Sponsor"
            entityName={names.sponsorName}
            data-testid={`${tid}-sponsor-name`}
          />
        )}

        {names.siteName && (
          <LaunchPadEntityName entityKindName="Study" entityName={names.studyName} data-testid={`${tid}-study-name`} />
        )}

        <div style={{alignSelf: 'center', marginTop: 'auto'}}>
          <Button
            color={'primary'}
            sx={{fontSize: 13, width: 80, height: 30}}
            data-testid={`${tid}-select`}
            LinkComponent={Link}
            href={scope.path}
            // property to get passed down into Link
            // spread here to avoid type errors
            {...{prefetch: false}}
          >
            Select
          </Button>
        </div>
      </KStack>
    </KWidget>
  );
}
