'use client';

import {useRouter} from 'next/navigation';
import {useEffect} from 'react';

/**
 * Due to https://github.com/vercel/next.js/issues/63388, server-side redirects on pages that have a Loading.tsx result in a double redirect which throws an error.
 *
 * Per https://github.com/Stand-With-Crypto/swc-web/issues/1143 the best workaround right now is to handle the redirect on the client side.
 */
export function ClientSideRedirect({url}: {url: string}) {
  const router = useRouter();

  useEffect(() => {
    router.replace(url);
  }, [router, url]);

  return null;
}
