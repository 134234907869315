import colors from '@/styles/colors';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import type {IconProps, SvgIconProps} from '@mui/material';
import type {Property} from 'csstype';
import {ReactElement, ReactNode, cloneElement} from 'react';
import {KTooltip, KTooltipProps} from './KTooltip';

export type KTooltipIconProps<T extends SvgIconProps | IconProps = SvgIconProps> = Partial<KTooltipProps> & {
  text?: ReactNode;
  icon?: ReactElement;
  iconProps?: T;
  'data-testid'?: string;
};

/**
 * Adds button positioned absolutely to top right to KTooltip.
 * Define tooltip content
 *
 * Add KTooltipProps props to customize tooltip
 *  for example style for positioning.
 * Use optional iconProps to customize icon.
 *
 */
export function KTooltipIcon<T extends SvgIconProps | IconProps = SvgIconProps>({
  text,
  icon,
  iconProps,
  style,
  ...tooltipProps
}: KTooltipIconProps<T>) {
  // Tooltip icon container defaults to 24px with 16px icon.
  // Using padding to set size of icon to 16.
  // Setting width and height causes the arrow to be out of position.
  // The theme spacing works when the tooltip displayed with given placement.
  // If the tooltip doesn't fit, the theme spacing is ignored not sure why atm.

  const tid = tooltipProps['data-testid'] ?? '';
  const tooltipStyleProps = {
    position: 'absolute' as Property.Position,
    right: '0px',
    top: '0px',
    maxHeight: '24px',
    ...style,
  };
  const tooltipIconSXProps = {
    color: colors.cobalt,
    opacity: 0.25,
    padding: '4px',
    ...iconProps?.sx,
  };

  return (
    <KTooltip
      data-testid={`${tid}-tooltip`}
      title={text!}
      placement={'top-end'}
      style={tooltipStyleProps}
      {...tooltipProps}
    >
      {icon ? (
        cloneElement(icon, {sx: tooltipIconSXProps})
      ) : (
        <InfoOutlinedIcon
          data-testid={`${tid}-tooltip-icon`}
          {...(iconProps as SvgIconProps)}
          sx={tooltipIconSXProps}
        />
      )}
    </KTooltip>
  );
}
