'use client';
import {KRow} from '@/components/layout/KRow';
import {KStack} from '@/components/layout/KStack';
import {
  passportRoleScopeOrganizationIDKey,
  passportRoleScopeSiteIDKey,
  passportRoleScopeSponsorIDKey,
  passportRoleScopeStudyIDKey,
} from '@koneksahealth/ts-authorizer';
import {Typography} from '@mui/material';
import {LaunchPadScope, LaunchPadWidget} from './LaunchPadWidget';

export function LaunchPadDashboardView({
  launchPadScopes,
  entityNameByID,
}: {
  launchPadScopes: LaunchPadScope[];
  entityNameByID: Record<string, string>;
}) {
  return (
    <KStack gap={'60px'} height={'100%'}>
      <KStack gap={'15px'} alignItems={'center'} textAlign={'center'} data-testid={'launch-pad-header'}>
        <Typography variant="body1" fontSize={34} lineHeight={'44px'}>
          Welcome to the Koneksa Platform
        </Typography>

        {launchPadScopes.length === 0 ? (
          <Typography variant="body1" fontSize={15} lineHeight={1}>
            You currently do not have access to any projects on the Koneksa Platform. Contact your project administrator
            if you need support.
          </Typography>
        ) : (
          <Typography variant="body1" fontSize={15} lineHeight={1}>
            Select from the options below to access the dashboard that
            <br />
            you are looking for
          </Typography>
        )}
      </KStack>

      <KRow
        columnGap={3}
        rowGap={5}
        justifyContent={'center'}
        flexWrap={'wrap'}
        data-testid={'launch-pad-widgets'}
        sx={{width: '100%'}}
      >
        {launchPadScopes.map((scope, index) => (
          <LaunchPadWidget
            key={`${scope[passportRoleScopeOrganizationIDKey]}::${scope[passportRoleScopeSponsorIDKey]}::${scope[passportRoleScopeStudyIDKey]}::${scope[passportRoleScopeSiteIDKey]}`}
            scope={scope}
            data-testid={`launchPad-${index}`}
            orgName={entityNameByID[scope[passportRoleScopeOrganizationIDKey]!] || ''}
            sponsorName={entityNameByID[scope[passportRoleScopeSponsorIDKey]!] as string}
            studyName={entityNameByID[scope[passportRoleScopeStudyIDKey]!] as string}
            siteName={
              entityNameByID[`${scope[passportRoleScopeStudyIDKey]!}-${scope[passportRoleScopeSiteIDKey]!}`] as string
            }
          />
        ))}
      </KRow>
    </KStack>
  );
}
